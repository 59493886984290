{
  "503": {
    "1": "Sorry, this page is not available at the moment. (503 Error)",
    "2": "Try updating the page after waiting a while."
  },
  "ActivateEmailLoginModal": {
    "1": "Now you can log in using your email address.",
    "2": "This email address has already been registered.",
    "3": "Set a login password.",
    "4": "A confirmation email will be sent to {{email}}.",
    "5": "Setting...",
    "6": "Set setting",
    "7": "Set a password"
  },
  "AnswerSelectBox": {
    "1": "Options",
    "2": "Check this again later.",
    "3": "Answer",
    "4": "Skip"
  },
  "CardForm": {
    "1": "Card number",
    "2": "Expiration date",
    "3": "Security code",
    "4": "Sorry. This card brand is not supported.",
    "5": "Failed to authenticate the card. Check the card number, etc. and try again.",
    "6": "Register your credit card",
    "7": "Change"
  },
  "CertificationModal": {
    "1": "Congratulations!",
    "2": "You've finished {{curriculum}}.",
    "3": "Certificate of Completion",
    "4": "it is certified that you have finished {{curriculum}}.",
    "5": "{{userName}}"
  },
  "ChangePasswordModal": {
    "1": "Your password has been changed.",
    "2": "The current password is incorrect.",
    "3": "An email has been sent. Click the link in the email and reset your password.",
    "4": "There is no account associated with this email address.",
    "5": "Authentication failed. Wait a while and try again.",
    "6": "Reset password",
    "7": "A link for resetting your password will be sent to the following email address.",
    "8": "Sending...",
    "9": "Send",
    "10": "A problem has occurred. Wait a while and try again."
  },
  "ChapterCard": {
    "1": "Assignments",
    "2": "Close chapter",
    "3": "Open chapter",
    "4": "Click ",
    "5": "here",
    "6": " to revise assignments.",
    "7": "Click ",
    "8": "here",
    "9": " for assignments.",
    "10": "How to use Google Colaboratory",
    "11": "Assignments has been submitted.",
    "12": "Click",
    "13": "here",
    "14": "for Google Colaboratory manual",
    "15": "Correction assignment not submitted",
    "16": "Correction assignment submitted",
    "17": "start this course",
    "18": "How to submit assignments",
    "19": " here",
    "20": " please check form",
    "21": "Click here for correction questions"
  },
  "ConfirmQuitTestModal": {
    "1": "Do you want to end the test?",
    "2": "Cancel",
    "3": "Yes",
    "4": "Reset"
  },
  "ConsumerLogin": {
    "1": "There is an incorrect or blank field.",
    "2": "Failed to send a confirmation email. No account with this email address is registered.",
    "3": "Reset password",
    "4": "Enter your registered email address.",
    "5": "An email with a link for resetting your password will be sent.",
    "6": "Welcome! You're logged in.",
    "7": "Payment",
    "8": "The email address is incorrect.",
    "9": "This account cannot currently be used.",
    "10": "This account does not exist. To use this account, newly register it.",
    "11": "The email address or password is incorrect.",
    "12": "This account is not registered to the plan.",
    "13": "Login",
    "14": "Login in accordance with the {{how}}.",
    "15": "Forgot my password",
    "16": "Authentication failed. Wait a while and try again.",
    "17": "An email has been sent. Click the link in the email and reset your password.",
    "18": "Send",
    "19": "Allow the use of third-party cookies.",
    "20": "The account does not exist.",
    "21": "Wait a while and try again.",
    "22": "Authentication failed.",
    "23": "Login",
    "24": "or",
    "25": "Logging in...",
    "26": "You have exceeded the maximum number of failed logins. Wait a while and try again."
  },
  "CourseDetailPageRightColumn": {
    "1": "Highly recommended",
    "2": "Recommended",
    "3": "Recommend courses",
    "4": "Login registration is required to purchase a course using tickets.",
    "5": "Use {{requiredNumOfTickets}} tickets.",
    "6": "Click here if you have a coupon.",
    "8": "Datasets used in this course",
    "9": "Download",
    "10": "Course Feedback",
    "11": "by taking our survey now.",
    "12": "Write feedback",
    "13": "To understand this course",
    "14": "To help us improve this course, we ask for",
    "15": "Delete code",
    "16": "Attended",
    "17": "Prerequisites",
    "18": " your cooperation in filling out a survey."
  },
  "CourseQuestionnaireModal": {
    "1": "Did this particular course meet your expectations?",
    "3": "Were the materials easy to understand?",
    "5": "Can you picture yourself applying what you have learned in this course in real life?",
    "6": "Rate 「{{name}}」",
    "7": "If you have a moment to spare, please let us know your experiece with us so far. ",
    "8": "We'd greatly appreciate your feedback.",
    "9": "Send",
    "10": "No option is selected",
    "11": "Not at all",
    "12": "Not particularly",
    "13": "Somewhat yes",
    "14": "Yes",
    "15": "Exceeded expectations",
    "16": "No option is selected",
    "17": "Very hard to understand",
    "18": "Hard to understand",
    "19": "Somewhat easy",
    "20": "Easy to understand",
    "21": "Very easy to understand",
    "22": "If you have any comments, please write here.",
    "23": "For improving the quality of learning contents,",
    "24": "To understand this course",
    "25": "Fairly",
    "26": "Met expectations",
    "27": "Absolutely Yes"
  },
  "CourseQuestionnaireThanksModal": {
    "1": "Thank you for your cooperation!",
    "2": "Your response has been submitted. ",
    "3": "We will continuously strive to improve our service.",
    "5": "Close",
    "6": "We sincerely appreciate you taking the time to provide us with your feedback."
  },
  "CourseSearchNav": {
    "1": "Target learners",
    "2": "Course type",
    "3": "Advanced search",
    "4": "Sub category",
    "5": "Free word search",
    "6": "Clear search conditions",
    "7": "Search",
    "8": "Search",
    "9": " (",
    "10": "searches)",
    "11": "Categories",
    "12": "All",
    "13": "E.g. Python",
    "14": "Clear"
  },
  "CoursesList": {
    "1": "There are ",
    "2": " courses in total. Select a course that interests you.",
    "3": "If you cannot find a course you are looking for, use the search function at the top of the page.",
    "4": "View all",
    "5": "Course List",
    "6": "Course List"
  },
  "Bookmark": {
    "1": "Bookmark",
    "2": "You can see your bookmarks.",
    "3": "Advanced search",
    "4": "Course details",
    "5": "Resume",
    "6": "Corrections Submitted",
    "7": "Corrections not submitted",
    "8": "Search",
    "9": "Bookmarks",
    "10": "Bookmarked Date {{date}}",
    "11": "Newest",
    "12": "Oldest",
    "13": "Completed",
    "14": "Required",
    "15": "Courses",
    "16": "Exercises",
    "17": "No courses added to bookmarks",
    "18": "If you find a course you want to review or watch later,",
    "19": "Let's add from the bookmark button on the course details screen",
    "20": "No exercises added to bookmarks",
    "21": "If you find an exercise you want to review or watch later,",
    "22": "Let's add it from the bookmark button on the exercise screen",
    "23": "Recommended"
  },
  "CoursesShow": {
    "1": "Review this course from the beginning.",
    "2": "Pick up where you left off.",
    "3": "Start",
    "4": "Are you sure that you want to delete the code you have written in this course?",
    "5": "(This action will not affect your learning history.)",
    "6": "Delete the code you wrote during this course.",
    "7": "Instructor",
    "8": "Code removed!",
    "9": "Next courses to take",
    "10": "Do you want to reset your course status?",
    "11": "This operation cannot be undone.",
    "12": "See more",
    "13": "Bookmarked",
    "14": "Add to bookmark",
    "15": "Reset",
    "16": "Course details",
    "17": "Close"
  },
  "EmailSettingModal": {
    "1": "A confirmation email has been sent. Authenticate your email address from the email.",
    "2": "The password is incorrect.",
    "3": "Change your login email address",
    "4": "A confirmation email will be sent to the specified address.",
    "5": "Check password",
    "6": "To continue operations, enter your password.",
    "7": "Set/change your login email address",
    "8": "This email address has already been registered.",
    "9": "Setting...",
    "10": "Set setting",
    "11": "A problem has occurred. Wait a while and try again."
  },
  "EvaluateButtonBlock": {
    "1": "Withdraw a high evaluation",
    "2": "Give a high evaluation for this exercise",
    "3": "Withdraw a low evaluation",
    "4": "Give a low evaluation for this exercise"
  },
  "ExamFinishedModal": {
    "1": "The test time has ended.",
    "2": "See test results"
  },
  "ExamResult": {
    "1": "Test results",
    "2": "Back to My Page",
    "3": "My Page",
    "4": "This test does not exist.",
    "5": "A problem has occurred. Wait a while and try again.",
    "6": "Exam Result",
    "7": "You are not allowed to show answer check page."
  },
  "ExamShow": {
    "1": "The test deadline has passed.",
    "2": "Retake the test",
    "3": "Test deadline",
    "4": "Note: ",
    "5": "Please note that the test can only be taken once and cannot be interrupted. Ensure you have sufficient time and a suitable environment before starting the test.",
    "6": "If you wish to retake the test, please contact your company's administrator.",
    "7": "You are asked to take the test again by your administrator.",
    "8": "Failed",
    "9": "{{score}} points ({{month}} {{day}}, {{year}})",
    "10": "You cannot take this test. Return to My Page.",
    "11": "This test does not exist. Return to My Page.",
    "12": "You are taking other test. Return to My Page.",
    "13": "Test information is incorrect. Please contact the administrator. Return to My Page.",
    "14": "You have not been granted a course license. Return to My Page.",
    "15": "This test cannot be taken by this team. Return to My Page.",
    "16": "A problem has occurred. Return to My Page.",
    "17": "Resume retaking the test",
    "18": "My Page",
    "19": "Exam Show",
    "20": "A problem has occurred. Wait a while and try again.",
    "21": "Go to the test results",
    "22": "Take Test",
    "23": "Resume Test",
    "24": "Passed",
    "25": "MMMM DD, YYYY",
    "26": "Test time",
    "27": "minutes",
    "28": "By pressing the button below, the test will begin immediately.",
    "29": "You are not allowed to access to this test results.",
    "30": "Whether you can immediately view your results after completing the test depends on the settings configured by the administrator.",
    "31": "Taking screenshots or photos of the screen is prohibited."
  },
  "ExcludedFromExamModal": {
    "1": "A course license is required to take the test.",
    "2": "Your course license has been disabled by the administrator.",
    "3": "If there is a problem, contact your company's administrator.",
    "4": "Back to My Page"
  },
  "Exercise": {
    "1": "Answering",
    "2": "Are you sure you want to reset?",
    "3": "Reset",
    "4": "Exercise title",
    "5": "Incorrect",
    "6": "Communication failed. Try again.",
    "7": "It may take up to {{number}} seconds to execute code.",
    "8": "Execution failed. Try again.",
    "9": "Execution time is too long. Review the code and try again.\n\n\n\nExecution cannot be performed correctly. Wait a while and try again.",
    "10": "That file cannot be executed.",
    "11": "Send Feedback",
    "12": "Finish input",
    "13": "Tablet input mode",
    "14": "Answer",
    "15": "Execution cannot be performed correctly. Wait a while and try again.",
    "16": "A problem has occurred. Wait a while and try again.",
    "17": "First, run the code.",
    "18": "Execution error. Review the code and try again."
  },
  "ExerciseBlockOutputImages": {
    "1": "Output image"
  },
  "ExerciseBlockQuestion": {
    "1": "Problem",
    "2": "Show the explanation again",
    "3": "Show tips",
    "4": "Display larger again",
    "5": "See Answer"
  },
  "ExerciseEditor": {
    "1": "Edit code",
    "2": "Close the input window"
  },
  "ExerciseModalDescription": {
    "1": "Return to details of course",
    "2": "Go to exercise window",
    "3": "Go to the exercise for this section",
    "4": "Complete this exercise and move on"
  },
  "ExerciseNotification": {
    "1": "Next",
    "2": "See the explanation and/or tips.",
    "3": "Correct!",
    "4": "Display larger again",
    "5": "Execution error. Review the code and try again.",
    "6": "There are no tips for this exercises.",
    "7": "Please move on!"
  },
  "Header": {
    "1": "My Page",
    "2": "Course List",
    "3": "Upgrade",
    "4": "Team Page",
    "5": "Operation of the control window from a smartphone is not supported.",
    "6": "Team Page for Tutors",
    "7": "Account Settings",
    "8": "For Beginner",
    "9": "FAQ",
    "10": "Logout",
    "11": "Make an inquiry",
    "12": "You will receive an answer within {{days}} business days.",
    "13": "Recommended Curriculum",
    "14": "",
    "15": "Notification",
    "16": "User Guide",
    "17": "Bookmark"
  },
  "InitialAgreementModal": {
    "1": "You have consented to our handling of personal information.",
    "2": "You failed to consent to our handling of personal information. Reload the page and try again.",
    "3": "Read before use (handling of personal information)",
    "4": "In the following context, \"Personal Information\" refers to the information you provide to us, including your email address, name, and any other information, in order to register with or use Aidemy's services that correspond to Personal Information as defined by Japan’s laws and regulations concerning the protection of personal information.",
    "5": "Aidemy handles your Personal Information in accordance with its ",
    "6": "Privacy Policy",
    "7": ".",
    "8": "Aidemy may provide your Personal Information to third parties only in the cases specified Items (1) and (2) below:",
    "9": "(1) The case where information about your attendance and performance, etc. stored within Aidemy’s services in a manner linking the information to your registered email address, name, etc. (said information shall be hereinafter collectively referred to as your “Attendance Record, etc.”) is provided to a party to the contract entered into for your use of the service (either the company for which you work, an affiliated company*1, or a third party related to you, including a company for which you are set to work*2)",
    "10": "You may withdraw your consent at any time. If you wish to withdraw your consent, please contact the following point of contact:",
    "11": "Personal Information Consultation Office, Aidemy, Inc.",
    "13": "Settings are being set...",
    "14": "Consent and go to next page",
    "15": "*1 Affiliated company: Provision to an affiliated company is made only in the case that the company for which you work and the affiliated company share an Aidemy service under the same environment.",
    "16": "Last update date: January 9, 2025",
    "18": "*2 A third party related to you, including a company for which you are set to work: In the case that the relationship between you and the entity which applied for the Aidemy service corresponds the relationship between a prospective employee and the company for which he/she is set to work, between a seconded employee and the company to which he/she is seconded, between an ordering party and contractor, between a dispatched worker and the company to which he/she is dispatched, or an equivalent relationship, the company for which he/she is set to work, the company to which he/she is seconded, the ordering party, the company to which he/she is dispatched, or an entity equivalent to it correspond to the third party.",
    "19": "(2) The case where a party to the contract entered into for your use of the service is an affiliated company of the company for which you work and the corresponding affiliated company is entrusted to disclose your Attendance Record, etc. to the company for which you work (or a third party related to you, including a company for which you are set to work) and provides your Attendance Record, etc. to the company for which you work (or a third party related to you, including a company for which you are set to work)",
    "20": "I agree to the above and Aidemy’s ",
    "21": "Privacy Policy",
    "22": " (",
    "23": "Cookie Policy",
    "24": " and ",
    "25": "GDPR Privacy Policy",
    "26": ") before using Aidemy's services.",
    "27": "Please note that the GDPR Privacy Policy applies if you use Aidemy’s services from outside Japan in the future."
  },
  "InitialMailSettingModal": {
    "1": "The setting for receiving emails has been set.",
    "2": "Failed to set the setting for receiving emails. Reload the page and try again.",
    "3": "Setting for receiving emails",
    "4": "Check the email notifications you want to receive.",
    "5": "Receive notifications about courses, functions, and course license expiration dates, etc. from Aidemy.",
    "6": "Receive notifications about promotions including campaigns, seminars, and use cases.",
    "7": "Settings are being set",
    "8": "Set settings",
    "9": "Next",
    "10": " *If you ",
    "11": "do not wish to receive ",
    "12": "such notifications, ",
    "13": " please uncheck the boxes."
  },
  "InitialPasswordSettingModal": {
    "1": "Set a password",
    "2": "Update the password.",
    "3": "Setting...",
    "4": "Set setting",
    "5": "",
    "6": "Authentication failed. Wait a while and try again.",
    "8": "Password must have at least 8 characters.",
    "9": "Password must be alphanumeric.",
    "10": "New password(8 characters or longer)"
  },
  "Inputs": {
    "1": "Set a password with 6 letters or more.",
    "2": "This password is not appropriate.",
    "3": "The email address format is incorrect."
  },
  "LastQuestionModal": {
    "1": "This is the last problem.",
    "2": "When you finish reviewing, click [End test].",
    "3": "OK",
    "4": "When you finish reviewing, click [End]."
  },
  "Login": {
    "1": "Forgot password?",
    "3": "There is no invitation information. Please contact the administrator.",
    "4": "The invitation information is incorrect. Please contact the administrator.",
    "5": "Login",
    "6": "email",
    "7": "password",
    "8": "Logging in...",
    "9": "The email address or password is incorrect.",
    "10": "The email address format is incorrect.",
    "11": "Email and password are required",
    "12": "Can't login?",
    "13": "urlPrefix"
  },
  "ManualMypage": {
    "1": "User Guide",
    "2": "Here are the basic operations of each screen."
  },
  "Modal": {
    "1": "Registered A confirmation email has been sent. Authenticate your email address from the email.",
    "2": "Login completed in 5 sec./Registration",
    "3": "Handling of personal Information",
    "4": "About sending specific email",
    "5": "Click here for other login methods",
    "6": "Forgot my password",
    "7": "There is an incorrect or blank field.",
    "8": "An email has been sent. Click the link in the email and reset your password.",
    "9": "Failed to send a confirmation email. No account with this email address is registered.",
    "10": "Authentication failed. Wait a while and try again.",
    "11": "Enter your registered email address.",
    "12": "An email with a link for resetting your password will be sent.",
    "13": "Reset Password",
    "14": "Send",
    "15": "Allow the use of third-party cookies.",
    "16": "The account does not exist.",
    "17": "Wait a while and try again.",
    "18": "Authentication failed.",
    "19": "Welcome! You're logged in.",
    "20": "I agree to the above and register as a new user.",
    "21": "Free Member Registration in 5 sec.",
    "22": "Log in the same way as before.",
    "23": "Registration",
    "24": "Login",
    "25": "or",
    "26": "Terms of Use",
    "27": "Click here to login",
    "28": "Click here to registration",
    "29": "Logging in...",
    "30": "Registration is processing...",
    "31": "Is this your first time using our service?"
  },
  "MypageNew": {
    "1": "Open",
    "2": "Close",
    "3": "Required",
    "4": "Recommended",
    "5": "Completed",
    "6": "Pickup News",
    "7": "BETA",
    "8": "Get the latest news on cutting-edge technology and the business landscape.",
    "9": "View All",
    "10": "*By clicking each article, you will leave our website and be directed to the Nikkei BP website (Japanese text only).",
    "11": "*By clicking each article, you will leave our website and be directed to the Nikkei BP website (Japanese text only).",
    "12": "There are no courses in progress.",
    "13": "Start learning from the \"Remaining Courses\" tab",
    "14": "Why don't you try some courses outside of the curriculum additionally?",
    "15": "Take your learning further with Aidemy's paid plans.",
    "16": "Aidemy currently offers about {{num}} courses, and new courses are added every month.",
    "17": "There are no courses in progress.",
    "18": "Let's learning from the \"Remaining Courses\" tab or  \"Courses in Progress\" one.",
    "19": "Go to Course List",
    "20": "Click here for individual customers",
    "21": "Click here for corporate customers",
    "22": "Learning Status",
    "23": "Curriculum Progress",
    "24": "Completed Courses",
    "25": "Remaining Days",
    "26": "Submitted assignments",
    "27": "There are no completed courses.",
    "28": "Keep learning with us.",
    "29": "Notification",
    "30": "Certificates",
    "31": "Pick up where you left off.",
    "32": "Course details",
    "33": "Go to details of test",
    "34": "points",
    "35": "Remaining Courses",
    "36": "Courses in Progress",
    "37": "Completed Courses",
    "38": "Test",
    "39": "Resume from uncompleted exercises",
    "40": "Corrections Submitted",
    "41": "Corrections not submitted",
    "42": "Courses assigned to you by admin",
    "43": "Recommended Courses",
    "45": "More Courses",
    "46": "Courses",
    "48": "New arrival courses",
    "49": "Courses available on Aidemy Free",
    "50": "days",
    "51": "Until {{date}}",
    "52": "Unlimited",
    "53": "Valid Indefinitely",
    "54": "Check with your company's management office regarding the actual period.",
    "55": "Course License Term: ",
    "56": "You are on Aidemy Free Plan.",
    "57": "You have no license.",
    "58": "Available tests",
    "59": "No tests available at this time.",
    "60": "Expired tests",
    "61": "To take an expired test, please contact your Aidemy administrator.",
    "62": "Tests already taken",
    "63": "You click \"Go to details of test\", can get test results.",
    "64": "No tests completed.",
    "65": "There are no \"Available tests\" or \"Tests taken\".",
    "66": "Your company's Aidemy administrator will set up the test and you will be able to take the test.",
    "67": "Sun",
    "68": "Mon",
    "69": "Tue",
    "70": "Wed",
    "71": "Thu",
    "72": "Fri",
    "73": "Sat",
    "74": "dddd, MMMM Do YYYY",
    "75": "Passed",
    "76": "Failed",
    "77": "Test deadline",
    "78": "Test Completed",
    "79": "My Page",
    "80": "Failed to retrieve test information",
    "81": "Please change your password for security reasons.",
    "82": "With this service, you can take any courses shown above.",
    "83": "See this page for the recommended curriculums according to the purpose.",
    "84": "The courses that Aidemy particularly recommends have the [Required] mark. Your learning will not be inhibited even if you do not complete the [Required] courses.",
    "85": "The criteria for issuance of a certificate to take the exam for the license in this program are as follows.",
    "86": "You must take the courses with the Required mark, and the progress of the curriculum must reach 100%.",
    "87": "You must answer correctly 60% or more (64 problems or more) of the final test.",
    "88": "When you satisfy the above conditions, Aidemy will issue a certificate to take the exam within about a week and send an email to the email address registered upon application.",
    "89": "You can take the final test from the link below.",
    "90": "Enter [select] in the Examinee No. field.",
    "91": "You must complete the courses by Sunday, August 7, 2022, when you take the E2022#2 exam (*).",
    "92": "(*) Friday, August 26 and Saturday, August 27, 2022",
    "93": "View more",
    "94": "You are on Aidemy Grit Plan.",
    "95": "Go to the Virtual Study Room !",
    "96": "Open it when you start learning to help you learn.",
    "97": "Get a speedy solution to the issues you are studying. (The Virtual Study Room is only available in Japanese.)",
    "98": "Open it when you start learning to help you learn. Get a speedy solution to the issues you are studying. (The Virtual Study Room is only available in Japanese.)",
    "99": "Test time",
    "100": "min",
    "101": "score, pass/fail"
  },
  "News": {
    "1": "Source",
    "2": "News",
    "3": "BETA",
    "4": "By clicking each article, you will leave our website and be directed to the Nikkei BP website (Japanese text only)."
  },
  "MypageSettings": {
    "1": "Reduce the file size and try again.",
    "2": "A member with the same unique ID already exists.",
    "3": "The account has been deleted.",
    "4": "A problem has occurred. Failed to delete the account. Wait a while and try again.",
    "5": "Images only in jpeg and png formats are supported.",
    "6": "Files of 50 KB or larger are not supported.",
    "7": "Japanese",
    "8": "The administrator has restricted the ability to change some items.",
    "9": "Please contact your company's administrator if you need to make a change.",
    "10": "Profile image",
    "11": "Change image",
    "12": "Team name",
    "13": "User name",
    "14": "Updating...",
    "15": "Not specified",
    "16": "Do you want to delete the account? Are you sure? (If you select ok, the service stops immediately, and the progress record, etc. is deleted.)",
    "17": "Leave service",
    "18": "Update",
    "19": "Account Settings",
    "20": "User name must be 50 characters or less.",
    "21": "A problem has occurred. Reload the page and try again.",
    "22": "Updated",
    "23": "Receive notifications about courses, functions, and course license expiration dates, etc. from Aidemy.",
    "24": "Receive notifications about promotions including campaigns, seminars, and use cases.",
    "25": "Account Settings",
    "26": "None",
    "27": "Password",
    "28": "Change Password",
    "29": "Email address",
    "30": "The email address format is incorrect.",
    "31": "Unique ID",
    "32": "Unique ID must be alphanumeric.",
    "33": "Unique ID must be 50 characters or less.",
    "34": "Affiliation",
    "35": "No affiliation specified",
    "36": "Setting for receiving emails",
    "37": "Please check the box if you agree to receive our emails.",
    "38": "Languages",
    "39": "Update",
    "40": "Cancel",
    "41": "Successfully updated.",
    "42": "Submit",
    "43": "Setting for payment information",
    "44": "Last 4 digits of credit card number"
  },
  "NotFound": {
    "1": "The specified page is not found.",
    "2": "Back to My Page"
  },
  "Plan": {
    "1": "Current plan:",
    "2": "Support plan:",
    "3": "Plan expiration date:",
    "4": "You can change the plan via the form below.",
    "5": "Change plan"
  },
  "Properties": {
    "1": "Difficulty",
    "2": "hours",
    "3": "Course"
  },
  "Question": {
    "1": "This test has ended. Return to My Page.",
    "2": "The test has started.",
    "3": "You cannot take this test at this time. Return to My Page.",
    "4": "The test has ended.",
    "5": "You cannot take this test at this time. Return to My Page.",
    "6": "Remaining time",
    "7": "This test cannot be taken by this team. Return to My Page.",
    "8": "You have not been granted a course license. Return to My Page.",
    "9": "This test does not exist. Return to My Page.",
    "10": "A problem has occurred. Wait a while and try again.",
    "11": "Exam",
    "12": "End test",
    "13": "End"
  },
  "QuestionBox": {
    "1": "Question {{number}}"
  },
  "QuestionBoxModal": {
    "1": "Question box",
    "2": "If you have any issues at all regarding the learning, ask a question.",
    "3": "We will answer your question within two business days.",
    "4": "Question details",
    "5": "The email address format is incorrect.",
    "6": "Sending...",
    "7": "Send",
    "8": "Please fill in here.",
    "9": "Your email address",
    "10": "Thank you.",
    "11": "",
    "12": "Your message has been sent.",
    "13": "You will receive a reply within 2 business days."
  },
  "QuestionnaireModal": {
    "1": "Register new member/Approx 2 min. questionnaire",
    "2": "Student",
    "3": "Worker/Other"
  },
  "ReconfirmQuitTestModal": {
    "1": "There are questions either you have not answered or marked with ”Check this again later.” Do you really want to finish the test?",
    "2": "Cancel",
    "3": "End"
  },
  "ResetPasswordModal": {
    "1": "Enter your registered email address.",
    "2": "Reset password",
    "3": "An email with a link for resetting your password will be sent.",
    "4": "Sending",
    "5": "Send",
    "6": "There is no account associated with this email address.",
    "7": "Authentication failed. Wait a while and try again.",
    "8": "An email has been sent. Click the link in the email and reset your password.",
    "9": "The email address format is incorrect.",
    "10": "Registered email address"
  },
  "RouteCourseCard": {
    "1": "See this course",
    "2": "Course"
  },
  "SearchedCoursesList": {
    "1": "No result found",
    "2": "Search results ({{number}})",
    "3": " ({{number}}) ",
    "4": "Course List"
  },
  "Tab": {
    "1": "Standard",
    "2": "Auto-compensation",
    "3": "Color scheme",
    "4": "Settings",
    "5": "Keybind setting",
    "6": "on",
    "7": "off",
    "8": "light",
    "9": "dark"
  },
  "UnactivatedExerciseModal": {
    "1": "A course license is required.",
    "2": "To continue the course, please contact the administrator.",
    "3": "Go to details of course"
  },
  "UserQuestionnaireModal": {
    "1": "Management",
    "2": "Sales",
    "3": "Manufacturing/Production/Quality control",
    "4": "Human resources/General affairs/Accounting",
    "5": "Other",
    "6": "Board member or higher",
    "7": "General manager or equivalent",
    "8": "Manager or equivalent",
    "9": "Assistant manager or equivalent",
    "10": "Staff",
    "11": "Humanities",
    "12": "Social science",
    "13": "Science",
    "14": "Engineering",
    "15": "Agriculture",
    "16": "Pharmaceutical",
    "17": "Home economics",
    "18": "Education",
    "19": "None",
    "20": "Hobby/Business less than 1 years",
    "22": "Initial Survey",
    "23": "Please take our survey and ",
    "24": "help us serve you better.",
    "25": "Year of birth",
    "26": "Select the year.",
    "28": "Position",
    "29": "Learning Purpose",
    "30": "Areas you want to work on",
    "31": "Hours spent studying per day",
    "32": "Major",
    "33": "Years of programming experience",
    "34": "Years of data science experience",
    "35": "Programming languages you have experience with",
    "36": "Send",
    "37": "{{number}} year or more as a professional programmer",
    "38": "less than 1 year / hobby",
    "39": "",
    "40": "Research/Development",
    "41": "Art",
    "42": "To improve AI literacy",
    "43": "To improve implementation skills in coding",
    "44": "To promote AI projects",
    "45": "As part of employee training",
    "46": "To prepare for an examination",
    "47": "As self-development",
    "48": "image recognition",
    "49": "anomaly detection",
    "50": "demand forecasting",
    "51": "language processing",
    "52": "Please fill in your purpose of learning.",
    "53": "Please fill in your major.",
    "54": "Please provide us the specific programming languages you have experience with.",
    "55": "Please fill in the AI-related issue you'd like to solve.",
    "56": "Other",
    "57": "E.g. PHP, Swift, Objective-C",
    "58": "Occupation",
    "59": "Select your answer.",
    "60": "{{number}} years or more as a professional programmer"
  },
  "UserQuestionnaireThanksModal": {
    "1": "We will keep striving to improve the quality of our services.",
    "2": "Please continue to use of Aidemy's services.",
    "3": "Thank you for your cooperation!",
    "4": "Your message has been sent.",
    "5": "Go to User Guide"
  },
  "Video": {
    "1": "The new video player cannot be used",
    "2": "The new video player cannot be used.",
    "3": "Contact the administrator of the team.",
    "4": "Check the network settings.",
    "5": "Speed",
    "6": "{{speed}}x",
    "7": "Standard",
    "8": "Quality",
    "9": "auto",
    "10": "Low quality",
    "11": "High quality",
    "12": "Play",
    "13": "Pause",
    "14": "Picture in Picture",
    "15": "Fullscreen",
    "16": "Exit Fullscreen",
    "17": "Mute",
    "18": "Unmute",
    "19": "Speed",
    "20": "Subtitles",
    "21": "subtitles off",
    "22": "Captions",
    "23": "Seek forward {{seconds}} seconds",
    "24": "Seek backward {{seconds}} seconds"
  },
  "Wantedly": {
    "1": "Completed",
    "2": "Add to Wantedly"
  },
  "__ArrowButtons": {
    "1": "Previous",
    "2": "Next"
  },
  "coursesList": {
    "1": "New courses",
    "2": "Start from here",
    "3": "Engineer courses",
    "4": "Business courses",
    "5": "For business personnel",
    "6": "For engineers",
    "7": "Mainly coding",
    "8": "Mainly videos",
    "9": "Mainly quizzes",
    "10": "Other",
    "11": "All",
    "12": "AI Application development course",
    "13": "AI Application development course",
    "14": "Natural language processing course",
    "15": "JDLA Deep Learning for ENGINEER course",
    "16": "Machine learning master course",
    "17": "Utilizing AI in Business",
    "18": "Transforming Organizations with Digital Transformation"
  },
  "curriculum": {
    "1": "AI Application development course",
    "2": "Data analysis course",
    "3": "Natural language processing course",
    "4": "AI Marketing course",
    "5": "Cloud AI development course",
    "6": "Practical data science course",
    "7": "JDLA Deep Learning for ENGINEER course",
    "8": "Machine learning master course",
    "9": "Machine learning model development course",
    "10": "JDLA Deep Learning for ENGINEER + AI application development course",
    "11": "JDLA Deep Learning for ENGINEER + data analysis course",
    "12": "JDLA Deep Learning for ENGINEER + natural language processing course",
    "13": "JDLA Deep Learning for ENGINEER + AI marketing course",
    "14": "Application development course"
  },
  "internetExplorerEnd": {
    "1": "Aidemy ended support for Internet Explorer on December 31, 2021. \nAidemy recommends using the latest version of Chrome, Edge, or Safari to comfortably use Aidemy. \n\n* Press Cancel to stop displaying this message."
  },
  "skipExerciseModal": {
    "1": "",
    "2": "Are you sure that you want to skip this exercise?",
    "3": "*If you have already completed this exercise, close this window and click [Complete this exercise and move on] or [Go to the exercise for this section] button. Unless you complete all exercises, your status will not change to completed.",
    "4": "Close",
    "5": "Skip"
  },
  "ResultBox": {
    "1": "Q"
  },
  "ManualMenu": {
    "1": "Search by Screen or Function",
    "2": "My Page",
    "3": "Course List and Details",
    "4": "Program and Video Exercise",
    "5": "Take test/Results",
    "6": "Account Settings"
  },
  "CourseCard": {
    "1": "",
    "2": "Review this course from the point where you stopped last time.",
    "3": "You have not yet started taking this course.",
    "4": "ticket",
    "5": "",
    "7": "Resume this course from the point where you stopped last time.",
    "8": "Course details",
    "9": "Start",
    "10": "A course license",
    "11": "Ticket",
    "12": "Resume",
    "13": "Review",
    "14": "Start for Free",
    "15": "is required."
  },
  "UserSettingDropdown": {
    "1": "My Page",
    "2": "Course List",
    "3": "Upgrade",
    "4": "Team Page",
    "5": "Operation of the control window from a smartphone is not supported.",
    "6": "Team Page for Tutors",
    "7": "Account Settings",
    "8": "User Guide",
    "9": "FAQ",
    "10": "Logout",
    "11": "Make an inquiry",
    "12": "You will receive an answer within {{days}} business days."
  },
  "ExerciseNavigation": {
    "1": "List of Exercises",
    "2": "Go to the top page of the course."
  },
  "ExerciseBlockQuiz": {
    "1": "multiple choice questions"
  },
  "ReleaseInfoModal": {
    "1": "Complete",
    "2": "Next",
    "3": "Close"
  },
  "Error": {
    "1": "An error has occurred. Reload the page and try again."
  }
}